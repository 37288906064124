<!--
File: RegionsDropdown.vue
Description: show the dropdown combo with the regions list .
-->
<template>
  <md-field>
    <label for="region_id">{{ label }}</label>
    <md-select v-model='region_id' name="region_id" id="region_id" :disabled="disabled" :required="is_required"
      @md-selected='onChange'>
      <md-option v-for='(rg, ind) in regions' :key='ind' :value='rg.region_id'>
        {{ rg.region_description }}
      </md-option>
    </md-select>
  </md-field>
</template>
<script>
  export default {
    name: 'regions-dropdown',

    props: {
      label: { default: null, type: String },
      initial_value: { default: null, type: Number },
      is_required: { default: false, type: Boolean },
      disabled: { default: false, type: Boolean }
    },

    data() {
      return {
        region_id: null,
        regionsListLoaded: false
      }
    },

    mounted() {
      this.$store.dispatch('GET_REGIONS_DROPDOWN', { show_all: !this.is_required }).then(() => {
        //console.log("RegionsDropdown: ", this.region_id, this.initial_value)
        this.regionsListLoaded = true
        if (this.initial_value && !this.region_id) this.region_id = this.initial_value
      })
    },

    methods: {
      onChange() {
        const region_desc = !this.region_id ? '' : this.regions.find(
          (element) => element.region_id == this.region_id).region_description

        this.$emit('input', this.region_id, region_desc)
      },
    },

    computed: {
      regions() {
        return this.$store.state.Dropdowns.regions;
      }
    },

    watch: {
      initial_value(newValue, oldValue) {
        if (newValue !== oldValue && this.regionsListLoaded) {
          this.region_id = newValue
        }
      },
    }
  }
</script>